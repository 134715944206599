<template>
  <div class="">
      <span class="text-sm font-medium text-gray-900">{{ title }}</span>
      <div class="flex space-x-2 mt-1 border-b border-gray-200 pb-2">
        <button type="button" @click="setTrue" :class="modelValue === true ? 'font-bold text-white bg-ifgreen hover:bg-ifgreen-dark' : 'text-gray-700 bg-white hover:bg-gray-50'" class="justify-center inline-flex items-center w-1/2 px-2.5 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen">
          {{$t("yes")}}
        </button>
        <button type="button" @click="setFalse" :class="modelValue === false ? 'font-bold text-white bg-ifgreen hover:bg-ifgreen-dark' : 'text-gray-700 bg-white hover:bg-gray-50'" class="justify-center inline-flex items-center w-1/2 px-2.5 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen">
          {{$t("no")}}
        </button>
      </div>
  </div>
</template>

<script>

export default {
  name: "YesNoSelect",
  emits: ['update:modelValue'],
  props: {
    title: String,
    modelValue: {
      type: Boolean,
      default: null
    }
  },
  methods: {
    setTrue () {
      this.$emit('update:modelValue', true)
    },
    setFalse () {
      this.$emit('update:modelValue', false)

    },
  }
}

</script>
