<template>
  <div :class="fixed && 'fixed w-full bottom-0 left-0 p-4 bg-white shadow-top z-20'">
    <span class="relative z-0 w-full inline-flex shadow-sm rounded-md">
      <button @click="prevStep" type="button" class="relative inline-flex items-center justify-center px-2 py-2 w-1/2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen">
        <span class="sr-only">Previous</span>
        <XMarkIcon v-if="step === 0" class="h-5 w-5 mr-2" aria-hidden="true" />
        <ChevronLeftIcon v-else class="h-5 w-5 mr-2" aria-hidden="true" />
        {{ step === 0 ? $t("cancel") : $t("back") }}
      </button>
      <button @click="nextStep" type="button" class="-ml-px relative inline-flex items-center justify-center px-2 py-2 w-1/2 rounded-r-md border border-gray-300 bg-ifgreen text-sm font-medium text-white hover:bg-ifgreen-dark focus:z-10 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen">
        <span class="sr-only">Next</span>
        {{ step >= steps - 1 ? $t("create") : $t("next") }}
        <ChevronRightIcon class="h-5 w-5 ml-2" aria-hidden="true" />
      </button>
    </span>
  </div>
</template>

<script>
import { ChevronRightIcon, ChevronLeftIcon, XMarkIcon } from '@heroicons/vue/24/outline'

export default {
  name: "AddDevice",
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
    XMarkIcon
  },
  emits: ['click-next', 'click-prev'],
  props: {
    fixed: {
      type: Boolean,
      default: true
    },
    step: Number,
    steps: Number
  },
  methods: {
    nextStep () {
      this.$emit('click-next')
    },
    prevStep () {
      this.$emit('click-prev')
    },
  }
};
</script>
