<template>
  <DeviceInstallingStep
    :name="name"
    :description="description"
    :stepNumber="stepIndex+1"
    :currentStep="currentStep"
    :completenessCondition="value.validated"
    @go-to-step="goToStep"
    :topOffset="topOffset"
  >
    <ul class="py-4 space-y-2">
      <li v-for="field in filteredFields" :key="field.name">
        <InputWithErrorVue
          v-if="field.type == 'string'"
          v-model="value[field.name]"
          type="text"
          :label="getLabel(field)"
          :placeholder="getPlaceholder(field)"
        />
        <NativeSelect
          v-if="field.type == 'list'"
          v-model="value[field.name]"
          :label="getLabel(field)"
          :options="listItems(field)"
        />
        <YesNoSelect
          v-if="field.type == 'yesno'"
          v-model="value[field.name]"
          :title="getLabel(field)"
        />
        <ImageInput
          v-if="field.type == 'image'"
          v-model="value[field.imageName]"
          :id="field.imageName"
          :label="getLabel(field)"
          :placeholder="getPlaceholder(field)"
          :watermark-text="deviceId"
        />
        <SignaturePad 
          v-if="field.type == 'signature'"
          v-model="value[field.imageName]"
          :id="field.imageName"
          :label="getLabel(field)"
          :placeholder="getPlaceholder(field)"
        />
      </li>
    </ul>
  </DeviceInstallingStep>
</template>

<script>
import DeviceInstallingStep from '@/components/deviceAction/DeviceInstallingStep.vue'
import InputWithErrorVue from "@/components/inputs/InputWithError.vue";
import NativeSelect from "@/components/inputs/NativeSelect.vue";
import YesNoSelect from "@/components/inputs/YesNoSelect.vue";
import ImageInput from "@/components/inputs/ImageInputNew.vue";
import SignaturePad from '@/components/inputs/SignaturePad.vue';
import { applyFormConditionalLogic, getDeviceSerialNumber } from '@/assets/js/helpers.js'

export default {
  components: {
    DeviceInstallingStep,
    InputWithErrorVue,
    NativeSelect,
    YesNoSelect,
    ImageInput,
    SignaturePad
  },
  emits: ["update:modelValue", "go-to-step"],
  props: {
    modelValue: Object,
    name: String,
    description: String,
    stepIndex: Number,
    currentStep: Number,
    schema: Array,
    device: Object,
    allFields: Array,
    valueFlat: Object,
    topOffset: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      value: {
        validated: false
      }
    }
  },
  computed: {
    filteredFields() {
      return applyFormConditionalLogic(this.schema, this.device, this.allFields, this.valueFlat)
    },
    deviceId () {
      return getDeviceSerialNumber(this.device)
    }
  },
  methods: {
    getLabel(field) {
      return field.labels?.[this.$i18n.locale] ? field.labels[this.$i18n.locale] : field.label
    },
    getPlaceholder(field) {
      return field.placeholders?.[this.$i18n.locale] ? field.placeholders[this.$i18n.locale] : field.placeholder
    },
    listItems (field) {
      return field.choices.map((choice) => {return { 
        value: choice.values?.[this.$i18n.locale] ? choice.values[this.$i18n.locale] : choice.value, 
        name: choice.values?.[this.$i18n.locale] ? choice.values[this.$i18n.locale] : choice.value 
      }});
    },
    goToStep () {
      this.$emit('go-to-step', this.stepIndex+1)
    },
    validate () {
      this.value.validated = this.filteredFields.every((field) => {
        const name = ['image', 'signature'].includes(field.type) ? field.imageName : field.name
        return Object.prototype.hasOwnProperty.call(this.value, name) && this.value[name] !== ""
      })
    },
    cleanUpValue () {
      Object.keys(this.value).forEach(key => {
        if (key != 'validated' && !this.filteredFields.find(field => {
          const name = ['image', 'signature'].includes(field.type) ? field.imageName : field.name
          return name == key
        })) delete this.value[key]
      })
    }
  },
  watch: {
    modelValue: {
      deep: true,
      handler () {
        this.value = this.modelValue
      }
    },
    value: {
      deep: true,
      handler () {
        this.validate()
        this.cleanUpValue()
        this.$emit('update:modelValue', this.value)
      }
    },
  }

}
</script>
