<template>
  <div :id="`step${stepNumber}`" class="relative">
    <StepIndicator
      @click="goToStep"
      class="sticky z-10 relative"
      :class="topOffset ? 'top-16' : 'top-0'"
      :id="formatedStepNumber"
      :name="name"
      :description="description"
      :status="completenessCondition ? 'complete' : (currentStep === stepNumber ? 'current' : 'upcoming')"
    />
    <div class="px-4 sm:px-6" :class="disabled && 'opacity-50 pointer-events-none'" v-show="currentStep === stepNumber">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import StepIndicator from '@/components/deviceAction/StepIndicator.vue'

export default {
  name: "DeviceInstallingStep",
  components: {
    StepIndicator
  },
  emits: ['go-to-step'],
  props: {
    completenessCondition: Boolean,
    currentStep: Number,
    stepNumber: Number,
    name: String,
    description: {
      type: String,
      default: null
    },
    topOffset: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    formatedStepNumber () {
      return this.stepNumber < 10 ? `0${this.stepNumber}` : this.stepNumber
    }
  },
  methods: {
    goToStep () {
      this.$emit('go-to-step', this.stepNumber)
    }
  }
}
</script>
