<template>
  <DeviceInstallingStep
    :stepNumber="0"
    :currentStep="currentStep"
    @go-to-step="goToStep"
    :name="`${device.schemaVersion ? $t('check') : $t('enter')} ${this.$t('template.deviceData')}`"
    :completenessCondition="device.schemaVersion ? currentStep > 0 : deviceParameters.validated"
    :disabled="disabled"
    :topOffset="topOffset"
  >
    <div v-if="device.schemaVersion" class="flex flex-col space-y-3 my-8">
      <div v-for="(entry, index) in deviceData" :key="index">
        <p class="text-sm font-medium text-gray-900" :class="index === 0 ? 'font-bold' : ''">
          {{ entry.value }}
        </p>
        <p class="text-xs text-gray-500 truncate">
          {{ entry.label }}
        </p>
      </div>
    </div>
    <div v-else class="pb-4">
      <DeviceTemplateForm
        v-model="deviceParameters"
        :schema="device.deviceTemplate.activeVersion.parametersSchema"
        :button="false"
      />
    </div>
  </DeviceInstallingStep>
</template>

<script>
import DeviceInstallingStep from '@/components/deviceAction/DeviceInstallingStep.vue'
import DeviceTemplateForm from "@/components/admin/DeviceTemplate/TemplateForm.vue";

export default {
  name: "SingleService",
  components: {
    DeviceInstallingStep,
    DeviceTemplateForm
  },
  emits: ["update:modelValue", "go-to-step"],
  props: {
    modelValue: Object,
    device: Object,
    currentStep: Number,
    disabled: {
      type: Boolean,
      default: false
    },
    topOffset: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      deviceParameters: {validated: false}
    }
  },
  computed: {
    deviceData () {
       if (this.device) {
         const deviceData = this.device.schemaVersion.parametersSchema
          .map(field => {return {label: field.label, value: this.device.parameters[field.name]}})
          .filter(field => {return field.value})
         if (this.device.step) deviceData.unshift({label: this.$t("template.serialNumber"), value: this.device.id})
         else deviceData.unshift({label: this.$t("template.typeOfDevice"), value: this.device.deviceTemplate.name})
         return deviceData
       } else {
         return []
       }
     }
  },
  methods: {
    goToStep () {
      this.$emit('go-to-step', 0)
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      deep: true,
      handler () {
        this.deviceParameters = this.modelValue
        if (this.device.schemaVersion) this.deviceParameters.validated = true
      }
    },
    deviceParameters: {
      deep: true,
      handler () {
        this.$emit('update:modelValue', this.deviceParameters)
      }
    }
  }
}
</script>
