<template>
  <DeviceInstallingStep
    :stepNumber="stepIndex"
    :currentStep="currentStep"
    @go-to-step="goToStep"
    :name="requireSignature ? $t('template.completeFormalities') : $t('template.fillInvestorsData')"
    :completenessCondition="value.validated"
    :topOffset="topOffset"
  >
    <div class="mt-4 mb-8">
      <CustomerDataForm
        v-if="newInstallation"
        ref="customerForm"
        v-model="value.customerData"
        @validate="validateCustomerForm"
      />
      <SignaturePad
        v-if="requireSignature"
        ref="signaturePad"
        v-model="value.signatureImage"
        :placeholder="$t('template.customerSignature')"
      />
    </div>
  </DeviceInstallingStep>
</template>

<script>
import DeviceInstallingStep from '@/components/deviceAction/DeviceInstallingStep.vue'
import CustomerDataForm from '@/components/installations/CustomerDataForm.vue'
import SignaturePad from '@/components/inputs/SignaturePad.vue'

export default {
  components: {
    DeviceInstallingStep,
    CustomerDataForm,
    SignaturePad
  },
  emits: ["update:modelValue", "go-to-step"],
  props: {
    modelValue: Object,
    newInstallation: Boolean,
    requireSignature: Boolean,
    stepIndex: Number,
    currentStep: Number,
    disabled: {
      type: Boolean,
      default: false
    },
    topOffset: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      value: {
        signatureImage: null,
        customerData: null,
        validated: false
      },
      customerFormValid: null
    }
  },

  methods: {
    goToStep () {
      this.$emit('go-to-step', this.stepIndex)
    },
    validateCustomerForm (result) {
      this.customerFormValid = result
    },
    getCustomerData (data) {
      this.value.customerData = data
    },
    validate () {
      return this.value.validated = (!this.requireSignature || this.value.signatureImage !== null) && (!this.newInstallation || this.customerFormValid)
    }
  },
  watch: {
    modelValue: {
      deep: true,
      immediate: true,
      handler () {
        this.value = this.modelValue
      }
    },
    value: {
      deep: true,
      handler () {
        if (!this.requireSignature) this.$refs.customerForm.validate()
        this.validate()
        this.$emit('update:modelValue', this.value)
      }
    },
  }
}
</script>
