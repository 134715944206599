<template>
  <label v-if="label" class="block mb-1 text-sm font-medium text-gray-700">{{ label }}</label>
  <div @click="startSig" class="flex rounded-md shadow-sm overflow-hidden mb-4">
    <div class="flex items-center justify-center flex-grow border rounded-l-md border-gray-300">
      <img v-if="signature" style="max-height: 50px;" :src="signature" />
      <p v-else class="px-4 w-full">{{ placeholder }}</p>
    </div>
    <button type="button" class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen">
    <PencilSquareIcon class="h-6 w-6 text-gray-400" aria-hidden="true" />
  </button>
  </div>
  <div v-if="show" class="fixed z-50 top-0 left-0 w-full h-full">
    <Vue3Signature
      ref="signaturePad"
      :sigOption="sigOptions"
      :w="`${sigWidth}px`"
      :h="`${sigHeight}px`"
      :disabled="false"
    />
    <div class="absolute sig-controls">
      <button @click="endSig" class="sig-button">
        <XMarkIcon class="w-6 h-6" />
      </button>
      <button @click="clearSig" class="sig-button middle border-gray-300">
        <ArrowPathIcon class="w-6 h-6" />
      </button>
      <button @click="saveSig" class="sig-button">
        <CheckIcon class="w-6 h-6" />
      </button>
    </div>
  </div>
</template>

<script>
import { CheckIcon, XMarkIcon, ArrowPathIcon, PencilSquareIcon } from '@heroicons/vue/24/outline'

export default {
  name: "SignaturePad",
  components: {
    CheckIcon,
    XMarkIcon,
    ArrowPathIcon,
    PencilSquareIcon
  },
  emits: ['update:modelValue'],
  props: {
    modelValue: String,
    label: String,
    placeholder: {
      type: String,
      default: "Dodaj podpis"
    },
    id: String
  },
  data () {
    return {
      sigOptions: {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255,255,255)"
      },
      sigWidth: '',
      sigHeight: '',
      show: false,
      signature: null
    }
  },
  methods: {
    startSig () {
      this.show = true
    },
    endSig () {
      this.show = false
    },
    clearSig () {
      this.$refs.signaturePad.clear()
    },
    saveSig () {
      this.signature = this.$refs.signaturePad.save('image/jpeg')
      this.$emit('update:modelValue', this.signature);
      this.show = false
    },
    handleSigPadResize () {
      this.sigHeight = window.innerHeight
      this.sigWidth = window.innerWidth
      if (this.show) {
        this.show = false;
        setTimeout(() => {
          this.show = true;
        }, 200)
      }
    },
  },
  mounted () {
    this.handleSigPadResize();
    window.addEventListener('resize', this.handleSigPadResize)
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.handleSigPadResize)
  }
}
</script>

<style scoped lang="scss">

.sig-button{
  @media (orientation: 'portrait') {
    @apply w-1/3 flex justify-center px-3 py-3 text-base font-medium text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none;
    &.middle{
      @apply border-l border-r
    }
  }
  @media (orientation: 'landscape') {
    @apply h-1/3 flex justify-center items-center px-3 py-3 text-base font-medium text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none;
    &.middle{
      @apply border-t border-b
    }
  }
}
.sig-controls {
  @media (orientation: 'portrait') {
    @apply bottom-0 left-0 w-full flex;
  }
  @media (orientation: 'landscape') {
    @apply bottom-0 right-0 h-full flex flex-col;
  }
}
</style>
