<template>
  <div>
    <ul role="list">
      <li>
        <DeviceCheckStep
          v-model="device.parameters"
          :device="device"
          :currentStep="currentStep"
          @go-to-step="goToStep"
          :top-offset="!incognito"
        />
      </li>
      <li v-for="(step, stepIndex) in filteredSteps" :key="stepIndex">
        <ConfiguredStep
          v-model="value[stepIndex]"
          :name="$t('template.fillCardText1') + `${formType == 'installation' ? $t('template.installation') : $t('template.inspection')}`+$t('template.fillCardText2')"
          :description="step.names?.[$i18n.locale] ? step.names[$i18n.locale] : step.name"
          :stepIndex="stepIndex"
          :currentStep="currentStep"
          :schema="step.schema"
          :device="device"
          :allFields="allFields"
          :valueFlat="valueFlat"
          @go-to-step="goToStep"
          :top-offset="!incognito"
        />
      </li>
      <li>
        <AddNotesStep
          v-model="notes"
          :stepIndex="filteredSteps.length+1"
          :currentStep="currentStep"
          @go-to-step="goToStep"
          :top-offset="!incognito"
        />
      </li>
      <li>
        <CustomerDataStep
          v-model="customer"
          :stepIndex="filteredSteps.length+2"
          :currentStep="currentStep"
          :newInstallation="newInstallation"
          @go-to-step="goToStep"
          :require-signature="requireSignature"
          :top-offset="!incognito"
        />
      </li>
    </ul>
    <div :class="preview ? 'py-6' : 'z-10 fixed left-0 right-0 bottom-0 px-4 border-t border-gray-200 py-4'" class="bg-white">
      <StepSwitch
        :step="currentStep"
        :steps="filteredSteps.length+3"
        :fixed="false"
        @click-prev="prevStep"
        @click-next="nextStep"
      />
    </div>
  </div>
</template>
<script>
import DeviceCheckStep from "@/components/deviceAction/DeviceCheckStep.vue";
import ConfiguredStep from "@/components/deviceAction/ConfiguredStep.vue";
import AddNotesStep from "@/components/deviceAction/AddNotesStep.vue";
import CustomerDataStep from "@/components/deviceAction/CustomerDataStep.vue";
import StepSwitch from "@/components/deviceAction/StepSwitch.vue";
import generatePdf from '@/assets/js/generatePdf.js'
import { api } from "@/assets/js/api-client.js";
import { applyFormConditionalLogic, getIDB, getFromIDB, addToIDB, deleteFromIDB } from '@/assets/js/helpers.js'
import { mapState } from 'vuex'
export default {
  components: {
    DeviceCheckStep,
    ConfiguredStep,
    AddNotesStep,
    CustomerDataStep,
    StepSwitch
  },
  emits: ['update:modelValue', 'submit', 'invalid'],
  props: {
    modelValue: Object,
    initialData: Object,
    preview: {
      type: Boolean,
      default: true
    },
    newInstallation: {
      type: Boolean,
      default: true
    },
    requireSignature: {
      type: Boolean,
      default: true
    },
    formType: String,
    propsDevice: Object,
    schemaVersionId: Number,
    schema: Array,
    allFields: Array,
    inspection: {
      type: Object,
      default: null
    },
    incognito: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      db: null,
      device: this.propsDevice,
      value: this.schema.map(() => {return {validated: false}}),
      notes: {},
      customer: {
        signatureImage: null,
        customerData: this.preview ? {
          customerEmail: 'jan.kowalski@test.com',
          customerName: 'Jan Kowalski',
          customerCompany: 'Test S.A.',
          customerAddress: 'Testowa 15',
          customerCity: 'Testowo',
          customerZipcode: '00-000',
          customerPhone: '+48000000000',
          installationDate: new Date().toLocaleString('sv').slice(0,10),
        } : {installationDate: new Date().toLocaleString('sv').slice(0,10)}
      },
      backup: {
        type: this.formType,
        schema: this.schemaVersionId
      },
      submiting: false,
      currentStep: 0
    }
  },
  computed: {
    ...mapState(['user','organisation','currentInstallation']),
    valueFlat () {
      const valueFlat = {}
      this.value.forEach(step => {
        Object.assign(valueFlat, step)
      })
      return valueFlat
    },
    filteredSteps () {
      return applyFormConditionalLogic(this.schema, this.device, this.allFields, this.valueFlat)
    },
    validated () {
      return this.filteredSteps.every((field) => {
        return this.value[field.name]
      })
    }
  },
  methods: {
    async submit () {
      this.submiting = true
      const pdf = await generatePdf(
        this.formType,
        this.user,
        this.newInstallation ? this.customer.customerData : this.currentInstallation,
        this.device,
        this.filteredSteps,
        this.value,
        this.notes,
        this.customer.signatureImage,
        this.inspection,
        this.preview,
        this.formType == 'installation' ? this.$i18n.locale : this.currentInstallation.language
      )


      const files = [{label: `${this.formType}-card`, type: 'application/pdf', base64: pdf.file}]
      this.filteredSteps.forEach((step, stepIndex) => {
        step.schema.forEach((field) =>{
          if (field.type == 'image' && this.value[stepIndex][field.imageName] && this.value[stepIndex][field.imageName].file) {
            //return {label: field.imageName, type: this.value[stepIndex][field.imageName].file.type, file: this.value[stepIndex][field.imageName].file}
            files.push({label: field.imageName, type: 'image/jpeg', base64: this.value[stepIndex][field.imageName].file.split(',')[1]})
            delete this.value[stepIndex][field.imageName].file
          }
        })
      })

      /*const files = this.allFields
        .filter(field => {return field.type == 'image' && this.valueFlat[field.imageName]})
        .map(field => {
          return {label: field.imageName, type: 'image/jpeg', base64: this.valueFlat[field.imageName].file}
        })
      */

      const data = {
        device: this.device,
        value: this.value,
        files,
        notes: this.notes,
        customer: this.newInstallation ? this.customer : {signatureImage: this.customer.signatureImage, customerData: this.currentInstallation},
        executionDate: pdf.executionDate
      }

      console.log(this.backup.id)
      await deleteFromIDB(this.db, 'forms', this.backup.id)
      this.$emit('submit', data)
    },
    async checkDevice () {
      this.$store.dispatch('setLoading', true)
      const schema = this.device.deviceTemplate.activeVersion.parametersSchema
      const deviceIdFields = schema
        .filter(field => {return field.barcodeScanner})
        .map(field => {return `parameters[]=$.${field.name}|${this.device.parameters[field.name]}`})

      const jsonQuery = `deviceTemplate.id=${this.device.deviceTemplate.id}&${deviceIdFields.join('&')}`
      api.get(`/organisations/${this.organisation}/devices?${jsonQuery}`).then(result => {
        if (result.data.length) {
          if (result.data[0].installation && result.data[0].installation.status != 'rejected') {
            this.$store.dispatch('setLoading', false)
            const data = {
              status: 'mounted-device',
              modalText: "Urządzenie o tym numerze seryjnym figuruje w bazie danych jako uruchomione. Nie można uruchomić 2 razy tego samego urządzenia. Czy jesteś pewny, że wprowadzone numery seryjne są porawne?"
            }
            this.$emit('submit', data)
          } else {
            this.$store.dispatch('saveDevice', result.data[0])
            this.device = result.data[0]
            this.generatePdf()
          }
        } else {
          const parameters = this.device.parameters
          delete parameters.validated
          const data = {
            deviceTemplate: `/device_templates/${this.device.deviceTemplate.id}`,
            schemaVersion: `/device_template_schema_versions/${this.device.deviceTemplate.activeVersion.id}`,
            organisation: `/organisations/${this.organisation}`,
            parameters
          }

          api.post('/custom_devices', data).then(result => {
            this.$store.dispatch('saveDevice', result.data)
            this.device = result.data
            this.generatePdf()
          }).catch(err => {
            console.log(err)
            this.$store.dispatch('setLoading', false)
          })
        }
      }).catch(err => {
        console.log(err)
        this.$store.dispatch('setLoading', false)

        const files = this.allFields
          .filter(field => {return field.type == 'image' && this.valueFlat[field.imageName]})
          .map(field => {
            return {label: field.imageName, type: this.valueFlat[field.imageName].file.type, file: this.valueFlat[field.imageName].file}
          })

        const signatures = {}
        this.allFields
          .filter(field => {return field.type == 'signature' && this.valueFlat[field.imageName]})
          .forEach(field => {
            signatures[field.imageName] = this.valueFlat[field.imageName]
          })

        const data = {
          status: 'offline',
          device: this.device,
          value: this.value,
          files,
          signatures,
          notes: this.notes,
          customer: this.newInstallation ? this.customer : {signatureImage: this.customer.signatureImage, customerData: this.currentInstallation}
        }
        this.$emit('submit', data)
      })
    },
    generatePdf () {
      generatePdf(
        this.formType,
        this.user,
        this.newInstallation ? this.customer.customerData : this.currentInstallation,
        this.device,
        this.filteredSteps,
        this.value,
        this.notes,
        this.customer.signatureImage,
        this.inspection,
        this.preview,
        this.$i18n.locale
      ).then(() => {
        if (!this.preview) {
          /*
          const files = this.allFields
            .filter(field => {return field.type == 'image' && this.valueFlat[field.imageName]})
            .map(field => {
              return {label: field.imageName, type: 'image/jpeg', base64: this.valueFlat[field.imageName].file.split(',')[1]}
            })

          files.unshift({label: `${this.formType}-card`, type: 'application/pdf', base64: result.file})

          const signatures = {}
          this.allFields
            .filter(field => {return field.type == 'signature' && this.valueFlat[field.imageName]})
            .forEach(field => {
              signatures[field.imageName] = this.valueFlat[field.imageName]
            })

          const data = {
            status: 'generated',
            device: this.device,
            files,
            signatures,
            value: this.value,
            notes: this.notes,
            customer: this.newInstallation ? this.customer : {signatureImage: this.customer.signatureImage, customerData: this.currentInstallation},
            executionDate: result.executionDate
          }
          this.$emit('submit', data)*/
        }
      })
    },
    prevStep () {
      if (this.currentStep > 0) this.currentStep--
      else if (this.formType == 'installation') {
        if (confirm('Czy na pewno chcesz przerwać instalację?')) {
          this.$router.push({name: 'installations'})
        }
      } else if (this.formType == 'inspection') {
        if (confirm('Czy na pewno chcesz przerwać przegląd?')) {
          this.$router.push({name: 'inspections'})
        }
      }
    },
    nextStep() {
      if (this.currentStep < this.filteredSteps.length+2) this.goToStep(this.currentStep+1)
      else {
        if (this.value.every(step => {return step.validated}) && this.customer.validated){
          //if (this.formType == 'installation' && !this.preview) this.checkDevice()
          //else this.generatePdf()
          if (this.preview) this.generatePdf()
          else this.submit()
        } else {
          this.$notify({
            title: 'Uwaga',
            text: 'Aby wygenerować PDF ukończ wszystkie kroki formularza',
            type: 'warning'
          })
        }
      }
    },
    goToStep(stepNumber) {
      const stepAvailable = stepNumber == 1 ? this.device.parameters.validated : this.value
        .filter((step, stepIndex) => {return stepIndex < stepNumber - 1})
        .every(step => {return step.validated})
      if (stepAvailable || this.preview) this.currentStep = stepNumber
      else {
        this.$notify({
          title: 'Uwaga',
          text: 'Najpierw wypełnij wszystkie pola w tym kroku',
          type: 'warning'
        })
      }
    }
  },
  async created() {
    console.log(this.newInstallation)
    this.db = await getIDB()
    const backups = await getFromIDB(this.db, 'forms')
    const backup = backups.find(item => {return item.type == this.formType && item.schema == this.schemaVersionId})
    //const backup = localStorage.getItem(`schema-${this.schemaVersionId}-${this.formType}-form`)
    if (!this.preview && backup) {
      this.backup = backup
      if (confirm("W pamięci zapisana jest ostatnia kopia robocza formularza. Czy chcesz ją wczytać?")) {
        if (this.backup.device) this.device.parameters = this.backup.device
        if (this.backup.form) this.value = this.backup.form
        if (this.backup.notes) this.notes = this.backup.notes
        if (this.backup.customer) this.customer = this.backup.customer
      } else if (this.initialData) {
        this.value = this.initialData
      }
    } else {
      const e = await addToIDB(this.db, 'forms', JSON.parse(JSON.stringify(this.backup)))
      this.backup['id'] = e.req.target.result
      if (this.initialData) {
        this.value = this.initialData
      }
    }
  },
  watch: {
    modelValue: {
      deep: true,
      handler () {
        this.value = this.modelValue
      }
    },
    'device.parameters': {
      deep: true,
      handler () {
        this.backup['device'] = this.device.parameters
      }
    },
    notes: {
      deep: true,
      handler () {
        this.backup['notes'] = this.notes
      }
    },
    customer: {
      deep: true,
      handler () {
        this.backup['customer'] = this.customer
      }
    },
    value: {
      deep: true,
      handler () {
        this.backup['form'] = this.value
        this.$emit('update:modelValue', this.value)
      }
    },
    backup: {
      deep: true,
      async handler (o, n) {
        console.log(this.backup, this.submiting, o.id, n.id)
        if (!this.preview && !this.submiting) {
          await addToIDB(this.db, 'forms', JSON.parse(JSON.stringify(this.backup)))
        }
      }
    },
    currentStep () {
      this.$router.push({hash: `#step${this.currentStep}`})
    }
  }

};
</script>
