<template>
  <div class="relative md:flex-1 md:flex border-t border-b border-gray-300 bg-white shadow">
    <div v-if="status === 'complete'" class="group flex items-center w-full">
      <span class="px-6 py-4 flex items-center text-sm font-medium">
        <span class="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-ifgreen rounded-full group-hover:bg-ifgreen-dark">
          <CheckIcon class="w-6 h-6 text-white" aria-hidden="true" />
        </span>
        <span v-if="false" class="ml-4 text-sm font-medium text-gray-900">{{ name }}</span>
        <span class="mt-0.5 ml-4 min-w-0 flex flex-col">
          <span class="text-xs font-semibold tracking-wide uppercase">{{ name }}</span>
          <span v-if="description" class="text-sm font-medium text-gray-500">{{ description }}</span>
        </span>
      </span>
    </div>
    <div v-else-if="status === 'current'" class="px-6 py-4 flex items-center text-sm font-medium" aria-current="step">
      <span class="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-ifgreen rounded-full">
        <span class="text-ifgreen">{{ id }}</span>
      </span>
      <span v-if="false" class="ml-4 text-sm font-medium text-ifgreen">{{ name }}</span>
      <span class="mt-0.5 ml-4 min-w-0 flex flex-col">
        <span class="text-xs font-semibold text-ifgreen tracking-wide uppercase">{{ name }}</span>
        <span v-if="description" class="text-sm font-medium text-gray-500">{{ description }}</span>
      </span>
    </div>
    <div v-else class="group flex items-center">
      <span class="px-6 py-4 flex items-center text-sm font-medium">
        <span class="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400">
          <span class="text-gray-500 group-hover:text-gray-900">{{ id }}</span>
        </span>
        <span v-if="false" class="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">{{ name }}</span>
        <span class="mt-0.5 ml-4 min-w-0 flex flex-col">
          <span class="text-xs font-semibold text-gray-500 tracking-wide uppercase">{{ name }}</span>
          <span v-if="description" class="text-sm font-medium text-gray-500">{{ description }}</span>
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import { CheckIcon } from '@heroicons/vue/24/solid'

export default {
  name: "StepIndicator",
  components: {
    CheckIcon,
  },
  props: {
    status: String,
    id: String,
    name: String,
    description: String
  }
};
</script>
