<template>
  <DeviceInstallingStep
    :stepNumber="stepIndex"
    :currentStep="currentStep"
    @go-to-step="goToStep"
    :name="$t('template.addNotes')"
    :completenessCondition="currentStep > stepIndex"
    :topOffset="topOffset"
  >
    <div class="my-8 space-y-2">
      <div>
        <label for="note" class="block text-sm font-medium text-gray-700">
          {{$t("template.installerNotes")}}
        </label>
        <div class="mt-1 relative">
          <textarea
            id="note"
            name="note"
            ref="installer"
            :value="modelValue?.installer"
            @input="update"
            class="appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-ifgreen focus:border-ifgreen sm:text-sm"
          />
        </div>
      </div>
      <div>
        <label for="note" class="block text-sm font-medium text-gray-700">
          {{$t("template.customerNotes")}}
        </label>
        <div class="mt-1 relative">
          <textarea
            id="note"
            name="note"
            ref="client"
            :value="modelValue?.client"
            @input="update"
            class="appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-ifgreen focus:border-ifgreen sm:text-sm"
          />
        </div>
      </div>
    </div>
  </DeviceInstallingStep>
</template>

<script>
import DeviceInstallingStep from '@/components/deviceAction/DeviceInstallingStep.vue'

export default {
  name: "SingleService",
  components: {
    DeviceInstallingStep,
  },
  emits: ["go-to-step", "update:modelValue"],
  props: {
    stepIndex: Number,
    currentStep: Number,
    topOffset: {
      type: Boolean,
      default: true
    },
    modelValue: Object
  },
  methods: {
    goToStep () {
      this.$emit('go-to-step', this.stepIndex)
    },
    update () {
      this.$emit('update:modelValue', {
        installer: this.$refs.installer.value,
        client: this.$refs.client.value
      })
    }
  }
}
</script>
